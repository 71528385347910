<template>
  <span class="isolate inline-flex rounded-md shadow-sm">
    <template v-for="button in buttons" :key="button.key">
      <button
        type="button"
        :class="classList(button.key)"
        @click="onClickButton(button.key)"
        v-text="
          button.localizedText
            ? localizeText(button.localizedText)
            : button.text
        "
      ></button>
    </template>
  </span>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

const localizeText = window.localizeText

interface IButtonData {
  /** The text that is displayed on the button, only if localizedText is empty */
  text: string

  /** The localized text that is passed to window.localizeText */
  localizedText?: string

  /** The key of the button, used to identify the button */
  key: string
}

const props = defineProps<{
  buttons: IButtonData[]

  /** The current toggle value */
  toggleValue?: string

  /** Whether the button group is in toggle mode */
  toggleMode?: boolean

  /** Whether the toggle value is stored in a temporary variable instead of v-model */
  toggleTempValue?: boolean

  /** Whether the toggle value should be set to the first button if it is not set */
  toggleToFirst?: boolean
}>()

// TODO: change 'click' to 'action' or something like that (add support for keyboard events, etc...)
const emit = defineEmits<{
  (event: 'click', key: string): void
  (event: 'update:toggle-value', value: string): void
}>()

/** The current toggle value */
const toggleValue = props.toggleTempValue
  ? ref<string>()
  : computed<string | undefined>({
      get: () => props.toggleValue,
      set: (value) => emit('update:toggle-value', value as string)
    })

/** Set the default toggle value to the first button */
if (props.toggleToFirst && toggleValue.value == null) {
  toggleValue.value = props.buttons[0].key
}

const onClickButton = (key: string) => {
  if (props.toggleMode) {
    toggleValue.value = key
  }

  emit('click', key)
}

const endClasses = 'rounded-r-md'
const startClasses = 'rounded-l-md'
const notFirstClasses = '-ml-px'
const defaultClasses =
  'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
const toggledClasses = 'bg-primary-500 avv-primary-bg-text hover:bg-primary-600'
const activeClasses = 'ring-blue-500 z-10 border-primary-500 outline-none'

const isFirst = (key: string) => key === props.buttons[0].key
const isLast = (key: string) =>
  key == props.buttons[props.buttons.length - 1].key
const isActive = (key: string) => key === toggleValue.value

const classList = (key: string) => {
  const result = {
    [startClasses]: isFirst(key),
    [notFirstClasses]: !isFirst(key),
    [endClasses]: isLast(key),
    [toggledClasses]: isActive(key) && props.toggleMode,
    [activeClasses]: isActive(key),
    [defaultClasses]: true
  }

  if (result[toggledClasses]) {
    delete result[defaultClasses]
    result[defaultClasses.replace('bg-white', '')] = true
  }

  return result
}
</script>
